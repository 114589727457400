import {
    ArrowsPointingInIcon,
    ArrowRightStartOnRectangleIcon,
    PhotoIcon,
    Square3Stack3DIcon,
    CameraIcon,
    KeyIcon,
    LockClosedIcon,
    ArrowPathRoundedSquareIcon,
    DocumentCheckIcon,
    ScissorsIcon,
    DocumentTextIcon,
    ArrowRightEndOnRectangleIcon, AdjustmentsVerticalIcon, AdjustmentsHorizontalIcon, GlobeAltIcon,

} from '@heroicons/react/24/outline'

import {
    FILETYPE_TYPE,
    FILETYPE_TOOLS_TYPE,
    EXTENSION_FILETYPE_TYPE,
    FILE_TOOL_TYPE, TOOL_ITEMS_TYPE, ToolParam, TOOL_TYPE
} from "@/types";

export const FILETYPE_TOOLS: FILETYPE_TOOLS_TYPE = {
    //pdf: ['compress', 'split', 'img', 'txt', 'ocr', 'html', 'epub', 'protect', 'unlock', 'merge', 'watermark'],
    pdf: ['compress', 'img', 'txt','html', 'ocr', 'grayscale', 'merge'],
    img: ['compress', 'pdf', 'grayscale', 'webp'],
    raw: ['jpg'],
    svg: ['compress'],
    txt: [],
    webp: [],
    html: [],
};

export const TOOLS_QUANTITY_MIN: Partial<{ [index in TOOL_TYPE]: number }> = {
    merge: 2
}


export const FILETYPE_MIMETYPES: { [index in FILETYPE_TYPE]: string[] } = {
    pdf: ['application/pdf'],
    img: ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/svg+xml'],
    raw: ['image/x-raw'],
    txt: ['text/plain'],
    webp: ['image/webp'],
    html: ['text/html'],
    svg: ['image/svg+xml'],
};

export const EXTENSION_FILETYPE: EXTENSION_FILETYPE_TYPE = {
    pdf: 'pdf',
    png: 'img',
    jpg: 'img',
    jpeg: 'img',
    svg: 'svg',
    webp: 'img',
    txt: 'txt',
    html: 'html',
};


export const TOOLS_ALIAS:{[k in string]:FILE_TOOL_TYPE} = {
    'svg-compress': 'img-compress',
}

export const TOOL_PARAMS: Partial<{ [index in FILE_TOOL_TYPE]: ToolParam[] }> = {
    'pdf-protect': [
        {
            name: 'password',
            type: 'password',
            label: 'Password',
            required: true,
        },
    ],
    'pdf-unlock': [
        {
            name: 'password',
            type: 'password',
            label: 'Password',
            required: true,
        },
    ],
    'pdf-watermark': [
        {
            name: 'text',
            type: 'text',
            label: 'Text',
            default: '',
        },
        {
            name: 'position',
            type: 'text',
            label: 'Position',
            default: 'center',
        },
        {
            name: 'size',
            type: 'number',
            label: 'Size',
            default: 24,
        },
        {
            name: 'color',
            type: 'text',
            label: 'Color',
            default: '#000000',
        },
    ],
    'pdf-merge': [
    ],
    'pdf-split': [
        {
            name: 'pages',
            type: 'text',
            label: 'Pages',
            default: '',
        }
    ],
    'pdf-ocr': [
        {
            name: 'language',
            type: 'text',
            label: 'Language',
            default: 'eng',
        }
    ],
    'img-compress': [
        {
            name: 'quality',
            type: 'number',
            label: 'Quality',
            default: 80,
        }
    ],
    'img-grayscale': [],
    'img-webp': [
        {
            name: 'quality',
            type: 'number',
            label: 'Quality',
            default: 80,
        }
    ],
    'img-pdf': [
        {
            name: 'orientation',
            type: 'text',
            label: 'Orientation',
            default: 'portrait',
        }
    ],
    'pdf-jpg': [
        {
            name: 'quality',
            type: 'number',
            label: 'Quality',
            default: 80,
        }
    ],
    'pdf-html': [],
    'raw-jpg': [
        {
            name: 'quality',
            type: 'number',
            label: 'Quality',
            default: 80,
        }
    ],
    'img-ocr':[],
}

type TOOLS_LIST_TYPE = {
    'active': TOOL_ITEMS_TYPE,
    'next': TOOL_ITEMS_TYPE,
}

export const TOOL_ITEMS:TOOLS_LIST_TYPE = {
    active: [
        {
            icon: ArrowsPointingInIcon,
            title: 'Compress PDF',
            metaTitle: 'Compress PDF Files Online for Free - Reduce PDF size',
            description: 'Reduce the size of your PDF files.',
            metaDescription: 'Easily compress your PDF files online for free, reducing file size while maintaining quality. Perfect for sharing and storage!',
            format: 'pdf',
            action: 'compress',
        },
        {
            icon: ArrowsPointingInIcon,
            title: 'Compress Image',
            metaTitle: 'Compress Images Online - Free Image Compression Tool',
            description: 'Reduce the size of your image files.',
            metaDescription: 'Compress your images online for free, reducing file size while preserving quality. Ideal for web use and storage!',
            format: 'img',
            action: 'compress',
        },
        {
            icon: AdjustmentsVerticalIcon,
            title: 'Image Greyscale',
            metaTitle: 'Convert Images to Greyscale Online - Free and Easy Tool',
            description: 'Convert images to greyscale.',
            metaDescription: 'Transform your images into greyscale online for free. Remove color noise or create artistic black-and-white effects effortlessly.',
            format: 'img',
            action: 'grayscale',
        },
        {
            icon: AdjustmentsHorizontalIcon,
            title: 'PDF Greyscale',
            metaTitle: 'Convert PDF to Greyscale Online - Optimize for Printing',
            description: 'Convert PDF to greyscale.',
            metaDescription: 'Convert your PDF files to greyscale for free with our easy online tool. Ideal for reducing color or optimizing for printing.',
            format: 'pdf',
            action: 'grayscale',
        },
        {
            icon: PhotoIcon,
            title: 'Image To Webp',
            metaTitle: 'Convert Images to WebP Format - Free Online Tool',
            description: 'Convert images to WebP format.',
            metaDescription: 'Optimize your images for the web by converting them to WebP format with our free online tool. Fast and efficient!',
            format: 'img',
            action: 'webp',
        },
        {
            icon: ArrowRightEndOnRectangleIcon,
            title: 'Image to PDF',
            metaTitle: 'Convert Images to PDF Online - Free and Easy Tool',
            description: 'Convert images to PDF files.',
            metaDescription: 'Create secure, shareable PDF files from your images online for free. Perfect for document storage and sharing!',
            format: 'img',
            action: 'pdf',
        },
        {
            icon: ArrowRightStartOnRectangleIcon,
            title: 'PDF to Image',
            metaTitle: 'Convert PDF to JPG Online - Document to Image Converter',
            description: 'Convert PDF files to JPG images.',
            metaDescription: 'Transform your PDFs into high-quality JPG images online for free. Simple and quick conversion!',
            format: 'pdf',
            action: 'jpg',
        },
        {
            icon: GlobeAltIcon,
            title: 'PDF to HTML',
            metaTitle: 'Convert PDF to HTML Online - Free and Easy Web Tool',
            description: 'Convert PDF files into HTML pages.',
            metaDescription: 'Easily convert your PDF files into HTML pages online for free. Perfect for creating web-friendly content!',
            format: 'pdf',
            action: 'html',
        },
        {
            icon: CameraIcon,
            title: 'RAW to JPEG',
            metaTitle: 'Free RAW to JPEG Converter - Transform RAW Files Online',
            description: 'Convert RAW images to JPEG.',
            metaDescription: 'Convert RAW image files to JPEG format online for free. Preserve quality and simplify sharing!',
            format: 'raw',
            action: 'jpg',
        },
        {
            icon: DocumentTextIcon,
            title: 'Image OCR',
            metaTitle: 'Extract Text from Images Online - Free OCR Tool',
            description: 'Extract text from images.',
            metaDescription: 'Use our free online OCR tool to extract text from images quickly and accurately. Ideal for document conversion and data retrieval.',
            format: 'img',
            action: 'ocr',
        },
        {
            icon: DocumentTextIcon,
            title: 'PDF OCR',
            metaTitle: 'Create Searchable PDFs with Free OCR - Extract Text',
            description: 'Make PDFs searchable with OCR.',
            metaDescription: 'Turn scanned PDFs into searchable documents for free using our advanced OCR tool. Simple, fast, and accurate!',
            format: 'pdf',
            action: 'ocr',
        },
        {
            icon: LockClosedIcon,
            title: 'Protect PDF',
            metaTitle: 'Encrypt PDF Files Online - Free Protection Tool',
            description: 'Secure your PDF files with encryption.',
            metaDescription: 'Encrypt your PDF files online for free. Protect sensitive information with password protection and advanced security!',
            format: 'pdf',
            action: 'protect',
        },
        {
            icon: Square3Stack3DIcon,
            title: 'Merge PDF',
            metaTitle: 'Merge PDF Files Online - Free and Easy Tool',
            description: 'Combine multiple PDF files into one.',
            metaDescription: 'Merge multiple PDF files into a single document online for free. Perfect for organizing and simplifying your files!',
            format: 'pdf',
            action: 'merge',
            input: 'multiple',
        },
    ],
    next: [
        {
            icon: ScissorsIcon,
            title: 'Split PDF',
            metaTitle: 'Split PDF Files Online - Free Document Separator Tool',
            description: 'Divide a PDF file into multiple files.',
            metaDescription: 'Easily split a single PDF into multiple files online for free. Extract specific pages or sections effortlessly!',
            format: 'pdf',
            action: 'split',
        },
        {
            icon: ArrowPathRoundedSquareIcon,
            title: 'Rotate Image',
            metaTitle: 'Rotate Images Online - Free Image Rotator Tool',
            description: 'Rotate your images with ease.',
            metaDescription: 'Quickly rotate your images to the desired angle using our free online tool. Perfect for adjustments and corrections!',
            format: 'img',
            action: 'rotate',
        },
        {
            icon: DocumentCheckIcon,
            title: 'Add Watermark',
            metaTitle: 'Add Watermarks to PDFs Online - Free Customization Tool',
            description: 'Add watermarks to your PDF files.',
            metaDescription: 'Easily add custom watermarks to your PDF files online for free. Protect and personalize your documents in seconds!',
            format: 'pdf',
            action: 'watermark',
        },
        {
            icon: KeyIcon,
            title: 'Unlock PDF',
            metaTitle: 'Unlock PDF Files Online - Remove Restrictions for Free',
            description: 'Decrypt your PDF files quickly.',
            metaDescription: 'Remove restrictions from your PDF files online for free. Unlock printing, copying, and editing capabilities easily!',
            format: 'pdf',
            action: 'unlock',
        },
    ],
};


